import React from "react";
import { Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import { isTablet, isMobile } from "react-device-detect";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Icons
import { CloseIcon } from "../icons/close-icon";

const CookieContainer = styled.div`
  &.tablet-view {
    & .cookie-container {
      /* top: 40px; */
      /* bottom: unset !important; */

      & .cookie-button {
        & svg {
          width: 15px;
        }
      }
    }
  }

  &:hover {
    & .cookie-container {
      background-color: #fe6143;

      & .cookie-text > p {
        color: #fff;
      }
    }

    & svg {
      & path {
        stroke: #fff;
      }
    }
  }

  & .button-container {
    line-height: 30px;
    display: block;
    height: 30px;
  }

  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 100;
    padding: 0 15px;

    transition: 250ms all ease;
    box-shadow: 0px 0 3px -3px rgb(0 0 0 / 10%);

    display: grid;
    grid-template-columns: 1fr 32px;
    /* grid-column-gap: 20px; */
    align-items: center;

    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.2px;
    background-color: #ffe664;
    color: #000;

    text-align: center;

    & .cookie-text {
      height: 30px;

      & p {
        margin: 0;
        color: #000;
      }
    }

    & .cookie-container {
      display: inline-block;
    }

    & .content-container {
      display: grid;
      grid-template-columns: 32px 1fr;
    }

    & .cookie-text > p {
      margin: 0;
      color: #000;
    }

    & .cookie-button {
      display: block;

      border: 0;
      padding: 0;
      margin: 0 0 0 auto;
      /* margin: 0 20px 0 auto; */

      line-height: 30px;
      height: 30px;

      cursor: pointer;

      & svg {
        width: 10px;
        height: auto;

        display: block;

        & path {
          transition: 250ms stroke ease;
        }
      }
    }

    & a.privacy-link {
      border: 1px solid #0043ea;
      transition: 250ms all ease;

      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.075em;
      padding: 3px 10px;

      transition: 250ms all ease;

      color: #000;
      cursor: pointer;
      display: inline-block;
    }

    @media (max-width: 900px) {
      line-height: 40px;
      height: 40px;

      & .cookie-text {
        height: 40px;

        /* & p {
          line-height: 37px;
        } */
      }
    }
  }
`;

export const Cookies = ({ location }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 901px)");

  return (
    <CookieContainer className={isTablet || isMobile ? `tablet-view` : ``}>
      <CookieConsent
        location="bottom"
        buttonText={<CloseIcon fill={`#000`} />}
        cookieName="ars-gdpr-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div className="spacer" />

        <div className={`cookie-text`}>
          {isDesktop ? (
            <p>
              We use cookies, click to view our{" "}
              <Link to={`/privacy-policy/`}>privacy policy</Link>
            </p>
          ) : (
            <p>
              <Link to={`/privacy-policy/`}>View Cookie Policy</Link>
            </p>
          )}
        </div>
      </CookieConsent>
    </CookieContainer>
  );
};
