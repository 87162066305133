import React, { useEffect } from "react";
import { useAsyncFn } from "react-use";

export const Instagram = () => {
  const [latestInstagramImage, getLatestInstagramImage] =
    useAsyncFn(async () => {
      const response = await fetch(
        `https://feeds.behold.so/zQ2iK8DfQqAxroGtFWix`
      );

      const result = await response.json();
      return result;
    }, []);

  useEffect(() => {
    getLatestInstagramImage();
  }, []);

  if (
    latestInstagramImage.loading === false &&
    latestInstagramImage.value !== undefined
  ) {
    return (
      <a href="https://instagram.com/anniestraus" target="_blank">
        <img
          src={latestInstagramImage.value[0].mediaUrl}
          alt={latestInstagramImage.value[0].caption}
        />
      </a>
    );
  } else {
    return null;
  }
};
