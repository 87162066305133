import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.aspectRatio + `%`};

  & iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    height: 100%;

    max-height: calc(100vh - 300px);

    // @media (max-width: 1024px) {
    //   padding: 25px 0 10px 0;
    // }
  }
`;

const Playbutton = styled.div`
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 44px;

  border-color: transparent transparent transparent #fff;
  transition: 300ms opacity ease;
  cursor: pointer;

  border-style: solid;
  border-width: 22px 0 22px 40px;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 20000;

  &:hover {
    opacity: 0.65;
  }
`;

const IframeWrapper = styled.div`
  position: relative;
  padding: 0 35px;

  width: 100%;
  max-height: calc(100vh - 300px);

  // @media (max-width: 1024px) {
  //   max-height: calc(100vh - 200px - 50px);
  // }
`;

export const Video = ({ video, playing, updateVideoPlayState }) => (
  <IframeWrapper>
    <IframeContainer aspectRatio={(video.height / video.width) * 100}>
      <Playbutton onClick={() => updateVideoPlayState(true)} />

      <ReactPlayer
        className="react-player"
        url={video.embed_url}
        width="100%"
        height="100%"
        playing={playing}
        controls={false}
        volume={1}
        onPause={() => updateVideoPlayState(false)}
        onEnded={() => updateVideoPlayState(false)}
        config={{
          vimeo: {
            playerOptions: {
              playsinline: true,
              portrait: false,
              byline: false,
              title: false,
            },
          },
        }}
        loading="lazy"
      />
    </IframeContainer>
  </IframeWrapper>
);
