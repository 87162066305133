import React from "react";
import styled from "styled-components";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background-color: #fff;
  z-index: 200;

  box-shadow: 0px 0 10px -3px rgb(0 0 0 / 10%);
`;

export const Header = ({ location }) => {
  return (
    <HeaderWrapper>
      <DesktopMenu location={location} />
    </HeaderWrapper>
  );
};
