/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = (property) => {
  if (property === "new") {
    return function (a, b) {
      return b.data.date_year - a.data.date_year;
    };
  } else if (property === "old") {
    return function (a, b) {
      return a.data.date_year - b.data.date_year;
    };
  } else if (property === "large") {
    return function (a, b) {
      return b.data.size.localeCompare(a.data.size);
    };
  } else if (property === "small") {
    return function (a, b) {
      return a.data.size.localeCompare(b.data.size);
    };
  }
};
