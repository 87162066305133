import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import ReactFullpage from "@fullpage/react-fullpage";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { FullpageModuleDesktop } from "../components/homepage/fullpage-desktop";
import { FullpageModuleMobile } from "../components/homepage/fullpage-mobile";

const Index = ({ data, location }) => {
  const height = use100vh();

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 901px)");

  const content = data.prismicHomepage.data.projects.filter(
    (project) => project.project.document !== null
  );

  return (
    <>
      <PageSeo seoTitle={null} seoText={null} seoImage={null} />
      {isDesktop ? (
        <FullpageModuleDesktop
          content={content}
          location={location}
          height={height}
        />
      ) : (
        <FullpageModuleMobile
          content={content}
          location={location}
          height={height}
        />
      )}
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  query {
    prismicHomepage {
      _previewable
      data {
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  for_sale
                  date_year
                  title {
                    richText
                  }
                  project_color
                  full_bleed
                  homepage_image {
                    alt
                    fluid {
                      src
                      srcSet
                    }
                  }
                  mobile_homepage_image {
                    alt
                    fluid {
                      src
                      srcSet
                    }
                  }
                  mobile_full_bleed
                }
              }
            }
          }
        }
      }
    }
  }
`;
