exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-prismic-content-page-url-js": () => import("./../../../src/pages/{PrismicContentPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-content-page-url-js" */),
  "component---src-pages-prismic-project-url-js": () => import("./../../../src/pages/{PrismicProject.url}.js" /* webpackChunkName: "component---src-pages-prismic-project-url-js" */)
}

