import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { isTablet, isMobile } from "react-device-detect";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Icons
import { CloseIcon } from "../components/icons/close-icon";

// Components
import { Mailchimp } from "../components/forms/mailchimp";
import { Instagram } from "../components/images/instagram";

const Page = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 30px 0; */
  padding: 30px 0 0 0;

  min-height: calc(${(props) => props.height}px - 30px);

  & .column {
    &.left {
      position: sticky;
      top: 30px;

      background-color: #f4f2f0;
      padding: 40px 60px;

      max-height: calc(${(props) => props.height}px - 30px);
      /* max-height: calc(${(props) => props.height}px - 30px - 30px); */
      height: 100%;

      & a {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;

        cursor: url(/icons/expand-icon@1x.png) 9 9, pointer !important;
        cursor: -webkit-image-set(
              url(/icons/expand-icon@1x.png) 1x,
              url(/icons/expand-icon@2x.png) 2x
            )
            9 9,
          pointer !important;

        & img {
          max-height: calc(${(props) => props.height}px - 60px - 80px);
          max-width: 800px;
          width: calc(100% - 60px - 60px);

          margin: 0 auto;

          object-fit: contain;
        }
      }
    }

    &.right {
      background-color: #fff;
      padding: 40px 60px 0 60px;
      margin: 0 0 70px 0;

      & p {
        font-size: 23px;
        line-height: 28px;
        letter-spacing: 0.2px;
        font-feature-settings: "pnum" on, "onum" on, "ss19" on, "ss15" on;

        & a {
          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #fe6143;
            }
          }
        }
      }

      & h2 {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.2px;
      }

      & .outer-text-container {
        max-width: 600px;
        margin: 0 auto;
      }

      & .text-container {
        & p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      & .content-container {
        & .row {
          margin: 2em 0;

          & p {
            margin: 0;
          }

          &:last-of-type {
            margin: 2em 0 0 0;
          }
        }
      }

      & .credits-container {
        & p {
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.2px;
        }
      }
    }
  }

  & .filter-container {
    position: fixed;

    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-columns: 32px auto 32px;
    padding: 0 15px;

    z-index: 10;
    background-color: #3f66c6;
    transition: 250ms background-color ease;

    & p {
      font-size: 18px;
      line-height: 30px;
      text-align: center;

      letter-spacing: 0.2px;
      font-feature-settings: "ss19" on;

      color: #fff;

      padding: 0 20px;
      margin: 0;

      transition: 250ms color ease;

      &.desktop-text {
        display: block;
      }

      &.mobile-text {
        display: none;
      }

      & a {
        display: block;
      }
    }

    & button {
      display: block;

      border: 0;
      padding: 0;
      margin: 0;

      line-height: 30px;
      height: 30px;
      width: 32px;

      cursor: pointer;

      & svg {
        width: 10px;
        height: auto;

        display: block;
        margin: 0 0 0 auto;

        & path {
          transition: 250ms stroke ease;
        }
      }
    }

    &:hover {
      background-color: #baccfb;

      & p {
        color: #000;
      }

      & button {
        & svg {
          & path {
            stroke: #000;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    display: block;
    grid-template-columns: unset;
    min-height: auto;

    padding: 105px 0 40px 0;
    background-color: #f4f2f0;

    & .column {
      &.left {
        position: relative;
        padding: 40px 15px 15px 15px;
        background-color: #f4f2f0;
        /* background-color: #fff; */
        max-height: none;
        top: unset;

        & a {
          position: relative;
          top: unset;
          transform: unset;

          & img {
            max-height: none;
            position: relative;
            width: 100%;
          }
        }
      }
      &.right {
        padding: 15px;
        margin: 0;
        background-color: #f4f2f0;
      }
    }

    & .filter-container {
      height: 40px;

      & p {
        &.desktop-text {
          display: none;
        }

        &.mobile-text {
          display: block;
          line-height: 38px;
        }
      }
    }
  }

  &.tablet-view {
    & .filter-container {
      /* top: 40px; */
      /* bottom: unset; */

      & button {
        line-height: 38px;
        height: 40px;

        & svg {
          width: 15px;
        }
      }
    }
  }
`;

const Information = ({ data }) => {
  const height = use100vh();

  const [year, setYear] = useState(2023);
  const [isFilterContainerVisible, setIsFilterContainerVisible] =
    useState(true);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, []);

  const content = data.prismicInformation.data.body.map((content, index) => {
    if (content.slice_type === `text_with_title`) {
      return (
        <div className="row text-with-title">
          <PrismicRichText field={content.primary.title_block.richText} />
          <PrismicRichText field={content.primary.text_block.richText} />
        </div>
      );
    }

    if (content.slice_type === `newsletter`) {
      return (
        <div className="row newsletter">
          <Mailchimp content={content} />
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicInformation.data.title.text}
        seoText={data.prismicInformation.data.text.text}
        seoImage={null}
      />
      <Page
        height={height}
        className={isTablet || isMobile ? `tablet-view` : ``}
      >
        <div className="column left">
          <Instagram />
        </div>
        <div className="column right">
          <div className="outer-text-container">
            <div className="text-container">
              <PrismicRichText
                field={data.prismicInformation.data.text.richText}
              />
            </div>

            <div className="content-container">
              {content}

              <div className="row credits-container">
                <p>© Annie Rickard Straus, {year}</p>
                <p>
                  <Link to={`/privacy-policy/`}>Privacy Policy</Link>
                </p>
                <p>
                  <Link to={`/terms-and-conditions/`}>
                    Terms and Conditions
                  </Link>
                </p>
                <br />
                <p>
                  Designed by{" "}
                  <a href="https://www.wolfehall.com/" target="_blank">
                    Wolfe Hall
                  </a>
                </p>
                <p>
                  Development by{" "}
                  <a href="https://kieranstartup.co.uk/" target="_blank">
                    Kieran Startup
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {isFilterContainerVisible && (
          <div className="filter-container">
            <div className="spacer" />
            <PrismicRichText
              field={data.prismicInformation.data.studio_straus_text.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="desktop-text">{children}</p>
                ),
              }}
            />

            <p className="mobile-text">Visit Studio Straus</p>

            <button onClick={() => setIsFilterContainerVisible(false)}>
              <CloseIcon fill={`#fff`} />
            </button>
          </div>
        )}
      </Page>
    </>
  );
};

export default withPrismicPreview(Information);

export const query = graphql`
  query {
    prismicInformation {
      _previewable
      data {
        title {
          text
          text
        }
        text {
          richText
          text
        }
        studio_straus_text {
          richText
        }
        body {
          ... on PrismicInformationDataBodyTextWithTitle {
            id
            slice_type
            primary {
              title_block {
                richText
              }
              text_block {
                richText
              }
            }
          }
          ... on PrismicInformationDataBodyNewsletter {
            id
            slice_type
            primary {
              title_block {
                text
              }
              text_block {
                text
              }
            }
          }
        }
      }
    }
  }
`;
