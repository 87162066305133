import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { use100vh } from "react-div-100vh";
import { isTablet, isMobile } from "react-device-detect";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Utils
import { dynamicSort } from "../components/utils/dynamic-sort";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
  min-height: calc(${(props) => props.height}px - 30px);
  background-color: #f4f2f0;

  & .content-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 30px 0;

    @media (min-width: 2000px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 1300px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }

    & .project-container {
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        padding: 30px;

        cursor: url(/icons/expand-icon@1x.png) 9 9, pointer !important;
        cursor: -webkit-image-set(
              url(/icons/expand-icon@1x.png) 1x,
              url(/icons/expand-icon@2x.png) 2x
            )
            9 9,
          pointer !important;
      }

      & .gatsby-image-wrapper {
        & img {
          z-index: 1;
          object-fit: contain !important;
        }
      }

      &.size-full-bleed {
        & a {
          padding: 0;
        }

        & .gatsby-image-wrapper {
          & img {
            z-index: 1;
            object-fit: cover !important;
          }
        }
      }

      & .fill {
        width: 100%;
        height: 100%;
        position: absolute;

        opacity: 0;
        transition: 300ms opacity ease;
      }

      &:hover {
        & .fill {
          opacity: 1;
        }
      }
    }
  }

  & .filter-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 10;
    background-color: #fff;
    box-shadow: 0px 0 3px -3px rgb(0 0 0 / 10%);

    & .mobile-filter-toggle {
      display: none;
      visibility: hidden;

      width: 100%;

      font-size: 18px;
      line-height: 39px;
      text-align: center;

      letter-spacing: 0.2px;
      font-feature-settings: "ss19" on;

      cursor: pointer;
      text-align: center;
    }

    & ol {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      & li {
        padding: 0 40px;
        transition: 250ms background-color ease, 250ms color ease;

        &:hover,
        &.current {
          background-color: #ffe664;
          color: #000;
        }

        & button {
          display: block;
          width: 100%;

          font-size: 18px;
          line-height: 30px;
          text-align: center;

          letter-spacing: 0.2px;
          font-feature-settings: "ss19" on;

          cursor: pointer;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 900px) {
    & .content-container {
      padding: 105px 0 0 0;
      margin: 40px 0;

      & .project-container {
        & a {
          padding: 15px;
        }
      }
    }

    & .filter-container {
      transition: 300ms transform ease;
      background-color: #ffe664;

      &.open {
        transform: translateY(0);
      }

      &.closed {
        transform: translateY(calc(100% - 40px));
      }

      & .mobile-filter-toggle {
        display: block;
        visibility: visible;
        color: #000;
      }

      & ol {
        display: block;
        grid-template-columns: unset;

        & li {
          &.current {
            display: none;
          }

          & button {
            line-height: 40px;
            color: #fff;
          }
        }
      }
    }
  }

  &.tablet-view {
    & .filter-container {
      & ol {
        & li {
          /* height: 50px; */
          & button {
            line-height: 35px;
          }
        }
      }

      /* &.closed {
        transform: translateY(calc(100% - 50px));
      } */

      & .mobile-filter-toggle {
        height: 40px;
        line-height: 40px;
      }
    }
  }
`;

const Gallery = ({ data }) => {
  const height = use100vh();
  const [filter, setFilter] = useState(`new`);
  const [isFilterListOpen, setIsFilterListOpen] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 601px)");

  const content = data.allPrismicProject.nodes
    .sort(dynamicSort(filter))
    .filter((project) => {
      if (filter === `sale`) {
        return project.data.for_sale;
      } else {
        return project;
      }
    })
    .map((project, index) => (
      <div
        className={`project-container ${
          project.data.gallery_image_full_bleed
            ? `size-full-bleed`
            : `size-standard`
        }`}
        key={`single_project_${project.id}_${index}`}
      >
        <Link to={project.url} state={{ from: `gallery` }}>
          <GatsbyImage
            image={
              isDesktop
                ? project.data.gallery_image.gatsbyImageData
                : project.data.mobileImage.gatsbyImageData
            }
          />

          <div
            className="fill"
            style={{
              background:
                project.data.project_color !== null
                  ? project.data.project_color
                  : `#F3F2F0`,
            }}
          />
        </Link>
      </div>
    ));

  return (
    <>
      <PageSeo seoTitle={`Gallery`} seoText={null} seoImage={null} />

      <Page
        height={height}
        className={isTablet || isMobile ? `tablet-view` : ``}
      >
        <div className="content-container">{content}</div>
        <div
          className={`filter-container ${isFilterListOpen ? `open` : `closed`}`}
        >
          <button
            onClick={() => setIsFilterListOpen(!isFilterListOpen)}
            className="mobile-filter-toggle"
          >
            {filter === `new` && `New to Old`}
            {filter === `old` && `Old to New`}
            {filter === `large` && `Large to Small`}
            {filter === `small` && `Small to Large`}
            {filter === `sale` && `For Sale`}
          </button>

          <ol>
            <li className={filter === `new` ? `current` : ``}>
              <button
                onClick={() => {
                  setIsFilterListOpen(false);
                  setFilter(`new`);
                }}
              >
                New to Old
              </button>
            </li>

            <li className={filter === `old` ? `current` : ``}>
              <button
                onClick={() => {
                  setIsFilterListOpen(false);
                  setFilter(`old`);
                }}
              >
                Old to New
              </button>
            </li>

            <li className={filter === `large` ? `current` : ``}>
              <button
                onClick={() => {
                  setIsFilterListOpen(false);
                  setFilter(`large`);
                }}
              >
                Large to Small
              </button>
            </li>

            <li className={filter === `small` ? `current` : ``}>
              <button
                onClick={() => {
                  setIsFilterListOpen(false);
                  setFilter(`small`);
                }}
              >
                Small to Large
              </button>
            </li>

            <li className={filter === `sale` ? `current` : ``}>
              <button
                onClick={() => {
                  setIsFilterListOpen(false);
                  setFilter(`sale`);
                }}
              >
                For Sale
              </button>
            </li>
          </ol>
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Gallery);

export const query = graphql`
  query {
    allPrismicProject(
      sort: { order: DESC, fields: data___date_year }
      filter: { data: { private_project: { eq: false } } }
    ) {
      nodes {
        id
        url
        data {
          for_sale
          date_year
          title {
            richText
          }
          project_color
          size
          gallery_image_full_bleed
          gallery_image {
            alt
            gatsbyImageData(aspectRatio: 1)
          }
          mobileImage: gallery_image {
            alt
            gatsbyImageData(aspectRatio: 1, srcSetMaxWidth: 500)
          }
        }
      }
    }
  }
`;
