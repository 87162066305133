exports.linkResolver = (doc) => {
  // URL for the information type
  if (doc.type === "information") {
    return `/${doc.uid}/`;
  }

  // URL for a project type
  if (doc.type === "project") {
    return `/project/${doc.uid}/`;
  }

  // URL for a page type
  if (doc.type === "content_page") {
    return `/${doc.uid}/`;
  }

  // Backup for all other types
  return "/";
};
