import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import { Header } from "./header";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, children }) => {
  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <>
      <Header location={location} />
      <Main>{children}</Main>
    </>
  );
};
