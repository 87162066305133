import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Slideshow
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Utils
// import { ImageOrientation } from "../utils/image-orientation";

// Components
import { Video } from "../video/video";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  min-height: ${(props) => props.height}px;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-slide-container,
  & .single-image-container,
  & .single-video-container {
    position: relative;

    width: 100%;

    max-height: ${(props) => props.height}px;
    height: 100%;
  }

  & .single-video-container,
  & .single-image-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  & .single-image-container {
    /* & .gatsby-image-wrapper { */
    max-height: ${(props) => props.height}px;
    height: 100%;
    width: 100%;

    & img {
      object-fit: contain !important;
      width: calc(100% - 35px - 35px);
      max-height: calc(${(props) => props.height}px - 35px - 35px);

      padding: 35px !important;

      @media (max-width: 900px) {
        width: calc(100% - 15px - 15px);
        max-height: calc(${(props) => props.height}px - 15px - 15px);
        padding: 15px !important;
      }
    }
  }

  & .single-image-container.size-full-bleed {
    /* & .gatsby-image-wrapper { */
    max-height: ${(props) => props.height}px;
    height: 100%;
    width: 100%;

    & img {
      object-fit: cover !important;
      width: 100%;
      height: ${(props) => props.height}px;
      max-height: ${(props) => props.height}px;

      padding: 0 !important;
    }
    /* } */
  }

  & .navigation-button {
    position: absolute;
    top: 0;
    bottom: 0;

    width: calc(50% - 22px);

    z-index: 10;

    &.next {
      right: 0;

      cursor: url(/icons/arrow-next-project@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-next-project@1x.png) 1x,
            url(/icons/arrow-next-project@2x.png) 2x
          )
          9 9,
        pointer !important;
    }

    &.prev {
      left: 0;

      cursor: url(/icons/arrow-prev-project@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-prev-project@1x.png) 1x,
            url(/icons/arrow-prev-project@2x.png) 2x
          )
          9 9,
        pointer !important;
    }

    @media (hover: none) {
      display: none;
    }
  }

  &.playing {
    & .navigation-button {
      width: 35px;
    }
  }
`;

export const Gallery = ({ data, height, currentSlide, setCurrentSlide }) => {
  const slideRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const updateVideoPlayState = (playState, evt) => {
    setIsPlaying(playState);
  };

  const content = data.map((content, index) => {
    if (content.slice_type === `image`) {
      return (
        <div
          className={`single-image-container ${
            content.primary.full_bleed1 ? `size-full-bleed` : `size-standard`
          }`}
          key={`single_image_${index}`}
        >
          <img
            src={content.primary.image.fluid.src}
            srcSet={content.primary.image.fluid.srcSet}
            alt={content.primary.image.alt}
            loading="lazy"
          />
          {/* <GatsbyImage image={content.primary.image.gatsbyImageData} /> */}
        </div>
      );
    } else {
      return (
        <div className="single-video-container" key={`single_image_${index}`}>
          <Video
            video={content.primary.video}
            playing={isPlaying}
            updateVideoPlayState={updateVideoPlayState}
          />
        </div>
      );
    }
  });

  return (
    <GalleryContainer
      height={height}
      className={isPlaying ? `playing` : `not-playing`}
    >
      {content.length > 1 ? (
        <>
          {slideRef.current !== null && (
            <button
              onClick={() => slideRef.current.goBack()}
              className="navigation-button prev"
            />
          )}

          <Slide
            ref={slideRef}
            arrows={false}
            transitionDuration={250}
            pauseOnHover={false}
            autoplay={false}
            infinite={true}
            canSwipe={content.length > 1 ? true : false}
            defaultIndex={0}
            onChange={(previous, next) => updateCurrentSlide(next)}
          >
            {content}
          </Slide>

          {slideRef.current !== null && (
            <button
              onClick={() => slideRef.current.goNext()}
              className="navigation-button next"
            />
          )}
        </>
      ) : (
        <div className="single-slide-container">{content}</div>
      )}
    </GalleryContainer>
  );
};
