import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";

// Icons
import { MobileLogo } from "../icons/mobile-logo";

const Nav = styled.nav`
  height: 30px;

  & ol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    & li {
      & a {
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.2px;
        font-feature-settings: "ss19" on;
        text-align: center;

        display: block;
        height: 30px;
        padding: 0 20px;

        transition: 250ms background-color ease, 250ms color ease;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: #ffe664;
            color: #000;
          }
        }

        &.current {
          background-color: #ffe664;
          color: #000;
        }
      }
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;

      & li {
        & a {
          line-height: 39px;
          height: 40px;
        }

        &.home-link {
          display: none;
        }
      }
    }
  }

  & .mobile-home-link {
    display: none;
    visibility: hidden;
  }

  @media (max-width: 900px) {
    height: 40px;

    &.homepage {
      & ol {
        & li {
          &:first-of-type {
            border-right: 1px solid #f4f2f0;
          }
        }
      }
    }

    & .mobile-home-link {
      display: block;
      visibility: visible;

      & a {
        display: block;
        text-align: center;

        & svg {
          width: 100%;
          height: auto;
          max-width: 375px;
        }
      }
    }
  }
`;

export const DesktopMenu = ({ location }) => {
  if (location.includes(`/project/`)) {
    return null;
  } else {
    return (
      <Nav className={location === `/` ? `homepage` : ``}>
        <ol>
          <li className="gallery-link">
            <Link to={`/gallery/`} activeClassName="current">
              Gallery
            </Link>
          </li>

          <li className="home-link">
            <Link to={`/`}>Annie Rickard Straus</Link>
          </li>

          <li className="information-link">
            <Link to={`/information/`} activeClassName="current">
              Information
            </Link>
          </li>
        </ol>

        <div className="mobile-home-link">
          <Link to={`/`}>
            <MobileLogo />
          </Link>
        </div>
      </Nav>
    );
  }
};
