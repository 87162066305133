import React from "react";

export const ArrowIcon = () => (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9454 8.14774L13.8308 0.981071L13.1211 1.6856L19.3896 8H0V9H19.3994L13.1191 15.3998L13.8328 16.1002L20.9474 8.85021L21.2931 8.49797L20.9454 8.14774Z"
      fill="black"
    />
  </svg>
);
