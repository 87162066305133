import React, { useState, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link } from "gatsby";
import styled from "styled-components";

const Page = styled.div`
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.backgroundColor};
  transition: 350ms background-color cubic-bezier(0.4, 0, 1, 1);

  & .all-projects-container {
    & .project-container {
      &.size-standard {
        padding: 80px 50px 50px 50px;

        & .image-container {
          max-height: calc(${(props) => props.height}px - 80px - 50px);

          & img {
            max-height: calc(${(props) => props.height}px - 80px - 50px);
            object-fit: contain !important;
          }
        }

        @media (max-width: 900px) {
          padding: 45px 15px 15px 15px;

          & .image-container {
            max-height: calc(${(props) => props.height}px - 45px - 15px);

            & img {
              max-height: calc(${(props) => props.height}px - 45px - 15px);
            }
          }
        }
      }

      &.size-full-bleed {
        padding: 30px 0 0 0;

        & .image-container {
          height: calc(${(props) => props.height}px - 30px);
          max-height: calc(${(props) => props.height}px - 30px);

          & img {
            height: calc(${(props) => props.height}px - 30px);
            max-height: calc(${(props) => props.height}px - 30px);
            object-fit: cover !important;
          }
        }
      }

      & a {
        cursor: url(/icons/expand-icon@1x.png) 9 9, pointer !important;
        cursor: -webkit-image-set(
              url(/icons/expand-icon@1x.png) 1x,
              url(/icons/expand-icon@2x.png) 2x
            )
            9 9,
          pointer !important;
      }

      & .fp-watermark {
        display: none;
        visibility: hidden;
      }
    }
  }

  & .navigation-button {
    position: absolute;
    left: 0;
    right: 0;
    height: 20vh;

    z-index: 10;

    &.next {
      bottom: 0;

      cursor: url(/icons/arrow-next@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-next@1x.png) 1x,
            url(/icons/arrow-next@2x.png) 2x
          )
          9 9,
        pointer !important;
    }

    &.prev {
      top: 0;

      cursor: url(/icons/arrow-prev@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/arrow-prev@1x.png) 1x,
            url(/icons/arrow-prev@2x.png) 2x
          )
          9 9,
        pointer !important;
    }

    @media (hover: none) {
      display: none;
    }
  }
`;
export const FullpageModuleMobile = ({ content, location, height }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [hasUserClicked, setHasUserClicked] = useState(false);

  const onLeave = (origin, destination, direction, trigger) => {
    setSlideIndex(destination.index);

    if (trigger === `wheel` || trigger === `keydown`) {
      setHasUserClicked(true);
    }
  };

  const nextSlide = () => {
    setHasUserClicked(true);
    if (typeof window !== `undefined`) {
      return window.fullpage_api.moveSectionDown();
    }
  };

  const prevSlide = () => {
    setHasUserClicked(true);
    if (typeof window !== `undefined`) {
      return window.fullpage_api.moveSectionUp();
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined` && hasUserClicked === false) {
      const autoPlay = setInterval(() => {
        window.fullpage_api.moveSectionDown();
      }, 7000);
      return () => clearInterval(autoPlay);
    }
  }, [hasUserClicked]);

  useEffect(() => {
    if (location !== undefined) {
      if (location.state !== undefined && location.state !== null) {
        if (
          location.state.incomingSlide !== undefined &&
          location.state.incomingSlide !== null
        ) {
          setSlideIndex(location.state.incomingSlide);
        }
      }
    }
  }, [location]);

  const sectionColors = content
    .filter((project) => project.project.document !== null)
    .map((project) => project.project.document.data.project_color);

  const mobileContent = content.map((project, index) => (
    <div
      className={`project-container section ${
        location.state !== undefined && location.state !== null
          ? location.state.incomingSlide !== undefined &&
            location.state.incomingSlide !== null
            ? index === location.state.incomingSlide
              ? `active`
              : ``
            : ``
          : ``
      } ${
        project.project.document.data.mobile_full_bleed
          ? `size-full-bleed`
          : `size-standard`
      }`}
      key={`single_project_${project.project.document.id}_${index}`}
    >
      <Link
        to={project.project.document.url}
        state={{ from: `homepage`, incomingSlide: index }}
      >
        <div className="image-container">
          {project.project.document.data.mobile_homepage_image.fluid !==
          null ? (
            <img
              src={
                project.project.document.data.mobile_homepage_image.fluid.src
              }
              srcSet={
                project.project.document.data.mobile_homepage_image.fluid.srcSet
              }
              alt={project.project.document.data.mobile_homepage_image.alt}
              loading="lazy"
            />
          ) : (
            <>
              {project.project.document.data.homepage_image.fluid !== null && (
                <img
                  src={project.project.document.data.homepage_image.fluid.src}
                  srcSet={
                    project.project.document.data.homepage_image.fluid.srcSet
                  }
                  alt={project.project.document.data.homepage_image.alt}
                  loading="lazy"
                />
              )}
            </>
          )}
        </div>
      </Link>
    </div>
  ));

  return (
    <Page height={height} backgroundColor={sectionColors[slideIndex]}>
      <>
        <button
          onClick={() => prevSlide()}
          className="navigation-button prev"
        />
        <div className="all-projects-container">
          <ReactFullpage
            continuousVertical={true}
            onLeave={onLeave}
            scrollingSpeed={1000}
            render={() => (
              <ReactFullpage.Wrapper>{mobileContent}</ReactFullpage.Wrapper>
            )}
          />
        </div>
        <button
          onClick={() => nextSlide()}
          className="navigation-button next"
        />{" "}
      </>
    </Page>
  );
};
