import React from "react";

export const CloseIcon = ({ fill }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00001 0.999999L11.0578 10.8266L21 1"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M21 20.8106L10.9422 10.984L1 20.8106"
      stroke={fill}
      strokeMiterlimit="10"
    />
  </svg>
);
